var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":"","elevation":"18"}},[_c('v-responsive',{attrs:{"aspect-ratio":16 / 6}},[_c('v-parallax',{staticStyle:{"height":"100%"},attrs:{"src":_vm.backgroundImageUrl}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"},on:{"click":function($event){$event.stopPropagation();return _vm.openNewTab(_vm.backgroundImageUrl)}}},'v-app-bar',attrs,false),on),[_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',{staticClass:"title white--text pl-0"},[_vm._v(" Article ")]),_c('v-spacer'),_c('v-menu',{attrs:{"left":"","bottom":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-text-field',{staticClass:"ml-3 font-weight-medium text-h5",attrs:{"label":"背景图链接","dark":""},model:{value:(_vm.backgroundImageUrl),callback:function ($$v) {_vm.backgroundImageUrl=$$v},expression:"backgroundImageUrl"}}),_c('v-text-field',{staticClass:"ml-3 font-weight-medium text-h5",attrs:{"label":"背景图版权","dark":""},model:{value:(_vm.backgroundImageCopyright),callback:function ($$v) {_vm.backgroundImageCopyright=$$v},expression:"backgroundImageCopyright"}})],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.backgroundImageCopyright)+" ")])]),_c('v-card-title',{staticStyle:{"cursor":"auto"}},[_c('v-text-field',{staticClass:"ml-3 font-weight-medium text-h5",attrs:{"label":"标题","dark":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)],1)],1),_c('v-card-subtitle',_vm._l((_vm.tags),function(tag){return _c('tag-chip',{key:tag.tagId,attrs:{"color":tag.tagColor,"selected":tag.selected,"icon":tag.tagIcon,"text":tag.tagName,"articleAmount":tag.articleAmount},on:{"update:selected":function($event){return _vm.$set(tag, "selected", $event)}}})}),1),_c('v-divider'),_c('v-card-text',[_c('mavon-editor',{ref:"md",style:([
                {
                  backgroundColor: _vm.$vuetify.theme.dark
                    ? '#1e1e1e'
                    : '#ffffff',
                  color: _vm.$vuetify.theme.dark ? '#ffffff' : '#24292e'
                }
              ]),attrs:{"id":"mavonEditor","placeholder":"在此输入正文...","editorBackground":_vm.$vuetify.theme.dark ? '#1e1e1e' : '#ffffff',"previewBackground":_vm.$vuetify.theme.dark ? '#1e1e1e' : '#ffffff',"toolbarsBackground":_vm.$vuetify.theme.dark ? '#1e1e1e' : '#ffffff'},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ms-7",attrs:{"color":"primary","disabled":!_vm.$store.state.isLogin,"depressed":""},on:{"click":_vm.submit}},[_vm._v(" 提交 ")])],1)],1)],1)],1)],1),_c('message-bar',{attrs:{"show":_vm.messageBar,"timeout":"2000","text":_vm.messageBarText},on:{"update:show":function($event){_vm.messageBar=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }